import React from "react";
import Layout from "../../components/Layout";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default class SubscribeForm extends React.Component {
  state = {
    email: "",
    responseMessage: "",
  };
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (formData.get("phone") !== "") {
      console.log("Form submission is spam!!!");
      return;
    }
    const result = await addToMailchimp(this.state.email);
    this.setState({ message: result.msg });
  };

  render() {
    return (
      <Layout>
        <section className="section-newsletter">
          <div
            className="full-width-image-container margin-top-0"
            style={{
              backgroundImage: `url('/img/blog-background.png')`,
            }}
          >
            <h1
              className="has-text-weight-bold is-size-1"
              style={{
                boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
                backgroundColor: "#f40",
                color: "white",
                padding: "1rem",
              }}
            >
              Subscribe To Our Newsletter
            </h1>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="content" style={{ textAlign: "center" }}>
              {/*TODO: Remove local hard-coded breaks & add styling via globalized SASS/CSS.*/}
              <div>
                <br></br>
                <div>
                  <h1>Predinfer Newsletter</h1>
                  <p>
                    <span>
                      Receive updates and insights, right in your mailbox.
                    </span>
                    <br></br>
                    <span> No spam ever. Unsubscribe anytime.</span>
                  </p>
                  <br></br>
                </div>
                <form
                  name="subscribeForm"
                  method="POST"
                  id="subscribe-form"
                  className="subscribe-form"
                  onSubmit={this.handleSubmit}
                >
                  <div
                    className="message"
                    style={{ backgroundColor: "#D64000", color: "white" }}
                    dangerouslySetInnerHTML={{ __html: this.state.message }}
                  />
                  <input
                    type="email"
                    id="email"
                    style={{ width: "360px", height: "40px" }}
                    name="email"
                    placeholder="Your email address*"
                    required
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                  <br></br>
                  <br></br>
                  <input type="hidden" name="phone" value=""></input>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      width: "360px",
                      height: "40px",
                    }}
                  >
                    Subscribe
                  </button>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <span className="check-mailbox" style={{ color: "gray" }}>*Check your "Inbox" or "Spam" folder for a confirmation email.</span>                  
                  <br></br>
                  <br></br>
                  <br></br>                                    
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
